// AUTOGENERATED - MANUALLY CHANGES WILL BE REVERTED BY THE GENERATOR
// IPoolAddressesProvider https://sepolia.etherscan.io/address/0x1f4Dae2F47F3607D0Af23fc1058C364CF350d464
export const POOL_ADDRESSES_PROVIDER = '0x1f4Dae2F47F3607D0Af23fc1058C364CF350d464';

// IPool https://sepolia.etherscan.io/address/0x5e0348B0457CEDB2C16fB23fb527BE0aE54EA976
export const POOL = '0x5e0348B0457CEDB2C16fB23fb527BE0aE54EA976';

// IPoolConfigurator https://sepolia.etherscan.io/address/0x40eC8bde91FE3d668A5b443aCdc20BA82B172080
export const POOL_CONFIGURATOR = '0x40eC8bde91FE3d668A5b443aCdc20BA82B172080';

// IFathomOracle https://sepolia.etherscan.io/address/0xEd201ce1603297f03f4793B404B31Dd0Fc6E2316
export const ORACLE = '0xEd201ce1603297f03f4793B404B31Dd0Fc6E2316';

// https://sepolia.etherscan.io/address/0x0000000000000000000000000000000000000000
export const PRICE_ORACLE_SENTINEL = '0x0000000000000000000000000000000000000000';

// IPoolDataProvider https://sepolia.etherscan.io/address/0x8603ACa48AD1e46bc63634c4700f1E3a17Ddf5bE
export const PROTOCOL_DATA_PROVIDER = '0x8603ACa48AD1e46bc63634c4700f1E3a17Ddf5bE';

// IACLManager https://sepolia.etherscan.io/address/0x29065CC861696D7265632174524760f876009729
export const ACL_MANAGER = '0x29065CC861696D7265632174524760f876009729';

// https://sepolia.etherscan.io/address/0x0db96Eb1dc48554bB0f8203A6dE449B2FcCF51a6
export const ACL_ADMIN = '0x0db96Eb1dc48554bB0f8203A6dE449B2FcCF51a6';

// ICollector https://sepolia.etherscan.io/address/0x8285745b4D7457F553ed1Fd8807aF772085e2d16
export const COLLECTOR = '0x8285745b4D7457F553ed1Fd8807aF772085e2d16';

// https://sepolia.etherscan.io/address/0xbE0d6cfF53e43C567e6C21C921D08B1Fec80BcFE
export const DEFAULT_INCENTIVES_CONTROLLER = '0xbE0d6cfF53e43C567e6C21C921D08B1Fec80BcFE';

// https://sepolia.etherscan.io/address/0x57e65E8D6DD73C969b0c7BE27eEEC2D10186EF65
export const DEFAULT_FM_TOKEN_IMPL_REV_1 = '0x57e65E8D6DD73C969b0c7BE27eEEC2D10186EF65';

// https://sepolia.etherscan.io/address/0xb97E081b8082fEE1693C5BC2713C405D945f52B0
export const DEFAULT_VARIABLE_DEBT_TOKEN_IMPL_REV_1 = '0xb97E081b8082fEE1693C5BC2713C405D945f52B0';

// https://sepolia.etherscan.io/address/0xDc98b9eEa967B4d68B87BF19e8f59Bb3D18B13c6
export const DEFAULT_STABLE_DEBT_TOKEN_IMPL_REV_1 = '0xDc98b9eEa967B4d68B87BF19e8f59Bb3D18B13c6';

// https://sepolia.etherscan.io/address/0x5fF337595B17B1Dd2357Dfa73896c8804135539e
export const EMISSION_MANAGER = '0x5fF337595B17B1Dd2357Dfa73896c8804135539e';

// https://sepolia.etherscan.io/address/0x8B7318dA861243B0a05e84b0e39b4C0c76180900
export const FAUCET = '0x8B7318dA861243B0a05e84b0e39b4C0c76180900';

// https://sepolia.etherscan.io/address/0xD13716EA1958714385096e94b3FcDdA3050A51f7
export const POOL_ADDRESSES_PROVIDER_REGISTRY = '0xD13716EA1958714385096e94b3FcDdA3050A51f7';

// https://sepolia.etherscan.io/address/0xe1d1f5d39934DD7080AAfcfADABDDD81c3fEb69f
export const UI_INCENTIVE_DATA_PROVIDER = '0xe1d1f5d39934DD7080AAfcfADABDDD81c3fEb69f';

// https://sepolia.etherscan.io/address/0x9C4de7DF712Ebcc0DBa18f397df61e8491a0aa25
export const UI_POOL_DATA_PROVIDER = '0x9C4de7DF712Ebcc0DBa18f397df61e8491a0aa25';

// https://sepolia.etherscan.io/address/0x57d4CD76d3FF605E711c370050bb775F0D498382
export const WALLET_BALANCE_PROVIDER = '0x57d4CD76d3FF605E711c370050bb775F0D498382';

// https://sepolia.etherscan.io/address/0x2106c60480030Aafa5E1296d339116b6517c4363
export const WETH_GATEWAY = '0x2106c60480030Aafa5E1296d339116b6517c4363';

export const CHAIN_ID = 11155111;
export const ASSETS = {
  FXD: {
    decimals: 18,
    UNDERLYING: '0x39C61c91eb75aAe76e18f06566d3329076A58B81',
    FM_TOKEN: '0x4Df5a01DcFD989E80795b5c3958D2072ecD62034',
    S_TOKEN: '0xb4Da4A621ef070aaAe4b808ab913E37a03c4D399',
    V_TOKEN: '0x54EA552E8B60ba649b7CcDd0a11896DF355C3de6',
    INTEREST_RATE_STRATEGY: '0xC919b833026F0eBC6aC77aD5529B7987fCAD515A',
    ORACLE: '0xfcb78b6b90965bCa835b7913b1983821126a697B',
  },
  WETH: {
    decimals: 18,
    UNDERLYING: '0xD0dF82dE051244f04BfF3A8bB1f62E1cD39eED92',
    FM_TOKEN: '0x95A95C42e53935bf8d9AB6f9cDbd05aB68aaA6d3',
    S_TOKEN: '0xf86264FB67D01737e0C28f3B62cf0E189DccDfED',
    V_TOKEN: '0x1c09087525418441999b70642CEFB5C91B628dc9',
    INTEREST_RATE_STRATEGY: '0x5AC8e13Aa2aBeB8A3732B9BC97fdA2A0A9fb9316',
    ORACLE: '0xB7666B789fa7b9d05021D77d99a79AE47B82bAa4',
  },
  USDF: {
    decimals: 6,
    UNDERLYING: '0x245d9acd7260eC59ef25D282379D5bf02CEe6Cb1',
    FM_TOKEN: '0x60bd2BfD18e8C769C16b393769051D6553b64c67',
    S_TOKEN: '0x6de241F48f2581427F860ED8e6e06c3fa70666e3',
    V_TOKEN: '0xF9a9dA12D5Fa720a00F9CACFeffd4B9A1C300A76',
    INTEREST_RATE_STRATEGY: '0x5CEe3fA7eDfa5BEe09496882a6B893d5d38b07FD',
    ORACLE: '0x85d5bf36D81906e022D58E902029e75Af7E60a9F',
  },
} as const;
export const E_MODES = {
  NONE: 0,
} as const;
