// AUTOGENERATED - MANUALLY CHANGES WILL BE REVERTED BY THE GENERATOR
// IPoolAddressesProvider https://xdc.blocksscan.io/address/0x37ab83e6a9B99DA3eAF00D1afdC45f50ee7625E5
export const POOL_ADDRESSES_PROVIDER = '0x37ab83e6a9B99DA3eAF00D1afdC45f50ee7625E5';

// IPool https://xdc.blocksscan.io/address/0x70d8005E3c8C7e383FE35Fa40156042F3393449F
export const POOL = '0x70d8005E3c8C7e383FE35Fa40156042F3393449F';

// IPoolConfigurator https://xdc.blocksscan.io/address/0x56f3A75C71C207a77c3b8c77a34FC89cF1a6DB66
export const POOL_CONFIGURATOR = '0x56f3A75C71C207a77c3b8c77a34FC89cF1a6DB66';

// IFathomOracle https://xdc.blocksscan.io/address/0x54348d953Abc4f167cbdeDe648095c1aF7DE355A
export const ORACLE = '0x54348d953Abc4f167cbdeDe648095c1aF7DE355A';

// https://xdc.blocksscan.io/address/0x0000000000000000000000000000000000000000
export const PRICE_ORACLE_SENTINEL = '0x0000000000000000000000000000000000000000';

// IPoolDataProvider https://xdc.blocksscan.io/address/0x7fa488a5C88E9E35B0B86127Ec76B0c1F0933191
export const PROTOCOL_DATA_PROVIDER = '0x7fa488a5C88E9E35B0B86127Ec76B0c1F0933191';

// IACLManager https://xdc.blocksscan.io/address/0xf73e7d6309A2DaDE5B698eD33dA929d2F2281526
export const ACL_MANAGER = '0xf73e7d6309A2DaDE5B698eD33dA929d2F2281526';

// https://xdc.blocksscan.io/address/0x594D425a6C9249F66a00C841A7a2A921b63a0a4C
export const ACL_ADMIN = '0x594D425a6C9249F66a00C841A7a2A921b63a0a4C';

// ICollector https://xdc.blocksscan.io/address/0xAedB3806A395edDf45c2700aB0AB67f99c06fAf4
export const COLLECTOR = '0xAedB3806A395edDf45c2700aB0AB67f99c06fAf4';

// https://xdc.blocksscan.io/address/0xf5e0C80114C0b0E3c4b55c217643E9a02699bB9b
export const DEFAULT_INCENTIVES_CONTROLLER = '0xf5e0C80114C0b0E3c4b55c217643E9a02699bB9b';

// https://xdc.blocksscan.io/address/0x5271D2bC5F2deCbF7124DA2349cc88E6Aa039364
export const DEFAULT_FM_TOKEN_IMPL_REV_1 = '0x5271D2bC5F2deCbF7124DA2349cc88E6Aa039364';

// https://xdc.blocksscan.io/address/0xfaA128B457FC7cBF9763A7Be66bF89662d9777FF
export const DEFAULT_VARIABLE_DEBT_TOKEN_IMPL_REV_1 = '0xfaA128B457FC7cBF9763A7Be66bF89662d9777FF';

// https://xdc.blocksscan.io/address/0x80e2eA68DB630660eFCa18780F24587967F3071B
export const DEFAULT_STABLE_DEBT_TOKEN_IMPL_REV_1 = '0x80e2eA68DB630660eFCa18780F24587967F3071B';

// https://xdc.blocksscan.io/address/0x049F146A33a16e454f3BE28bb0bc18c12C96a894
export const EMISSION_MANAGER = '0x049F146A33a16e454f3BE28bb0bc18c12C96a894';

// https://xdc.blocksscan.io/address/0xDAb3B99eb3569466750c436d6F4c99d57850Cc89
export const POOL_ADDRESSES_PROVIDER_REGISTRY = '0xDAb3B99eb3569466750c436d6F4c99d57850Cc89';

// https://xdc.blocksscan.io/address/0xA69c5468Aa4ab263a250fD9dA4322e58370F2bB2
export const UI_INCENTIVE_DATA_PROVIDER = '0xA69c5468Aa4ab263a250fD9dA4322e58370F2bB2';

// https://xdc.blocksscan.io/address/0x5f7001B6Dc957dC5B2F78f0BC3aFbFc1fE628A18
export const UI_POOL_DATA_PROVIDER = '0x5f7001B6Dc957dC5B2F78f0BC3aFbFc1fE628A18';

// https://xdc.blocksscan.io/address/0x7C724DEaD5012Eb4C9e2d1529cF0353e767C82Cd
export const WALLET_BALANCE_PROVIDER = '0x7C724DEaD5012Eb4C9e2d1529cF0353e767C82Cd';

// https://xdc.blocksscan.io/address/0x57Ba8bAA7c3Ff6606751859f1CED9f68819C2f41
export const WETH_GATEWAY = '0x57Ba8bAA7c3Ff6606751859f1CED9f68819C2f41';

export const CHAIN_ID = 50;
export const ASSETS = {
  FXD: {
    decimals: 18,
    UNDERLYING: '0x49d3f7543335cf38Fa10889CCFF10207e22110B5',
    FM_TOKEN: '0xEC826980367dABBAA28F614B8D0e14548dCca37b',
    S_TOKEN: '0xe82b0F5CDf092Bf01Ae56898bB35b1E77fc60aC2',
    V_TOKEN: '0xcF5b5C4DfeA09a0Ad129717BfbbCA750c362E795',
    INTEREST_RATE_STRATEGY: '0x12936376CCb51877ed2135b985aEe1d011e173CA',
    ORACLE: '0x03396fE4E58A0778679E2731564f064FA5256C6E',
  },
  WXDC: {
    decimals: 18,
    UNDERLYING: '0x951857744785E80e2De051c32EE7b25f9c458C42',
    FM_TOKEN: '0xDAEf7d4000fb0e511C9f2dEEAE602d9c8fcb28f7',
    S_TOKEN: '0xcbf718E6802E646D6d016912453E1ECb1BdB0DcA',
    V_TOKEN: '0x10eB945e14131Fb55B2F432d826F4e09d718276D',
    INTEREST_RATE_STRATEGY: '0x99E7d2d9B8349B70aae31e5213c54fd022fd5DCF',
    ORACLE: '0x8d470BDE66eE52981D7D29Bc2e6FCa3b4DC17879',
  },
  xUSDT: {
    decimals: 6,
    UNDERLYING: '0xD4B5f10D61916Bd6E0860144a91Ac658dE8a1437',
    FM_TOKEN: '0x1C3bBc4FA17128711c238Bc50Bd0AE85D35C2515',
    S_TOKEN: '0x2F6C3d501cfD528D78c7C1Da3B8Ea37Ba85BDB93',
    V_TOKEN: '0x98dC1115ADdcdD2eF67c87D35fAF0b835b3F746D',
    INTEREST_RATE_STRATEGY: '0xB34A51D8443219bdA8BFBA5826B7907Bc4032e11',
    ORACLE: '0x321F084B78756C5550e6Ab200d88BB2602BCE1A2',
  },
} as const;
export const E_MODES = {
  NONE: 0,
  STABLECOINS: 1,
} as const;
