// AUTOGENERATED - MANUALLY CHANGES WILL BE REVERTED BY THE GENERATOR
// IPoolAddressesProvider https://apothem.blocksscan.io/address/0x92279e2DD0022099f00320d07A51Df0C1e593af9
export const POOL_ADDRESSES_PROVIDER = '0x92279e2DD0022099f00320d07A51Df0C1e593af9';

// IPool https://apothem.blocksscan.io/address/0x21C7353A1D3e28C7879072F7bc6e8cA7ea5653AA
export const POOL = '0x21C7353A1D3e28C7879072F7bc6e8cA7ea5653AA';

// IPoolConfigurator https://apothem.blocksscan.io/address/0x5e4ecB622827Df636B1bd98Bd75bd89c606d2576
export const POOL_CONFIGURATOR = '0x5e4ecB622827Df636B1bd98Bd75bd89c606d2576';

// IFathomOracle https://apothem.blocksscan.io/address/0x177193DD3Fa2C3F4C64671331a471D42FD420Fd8
export const ORACLE = '0x177193DD3Fa2C3F4C64671331a471D42FD420Fd8';

// https://apothem.blocksscan.io/address/0x0000000000000000000000000000000000000000
export const PRICE_ORACLE_SENTINEL = '0x0000000000000000000000000000000000000000';

// IPoolDataProvider https://apothem.blocksscan.io/address/0x88860215107B2e01f566297BAE60DaDAdF7EB365
export const PROTOCOL_DATA_PROVIDER = '0x88860215107B2e01f566297BAE60DaDAdF7EB365';

// IACLManager https://apothem.blocksscan.io/address/0x9d370446bf1c6416D8dB846B606A7aB236F28E3d
export const ACL_MANAGER = '0x9d370446bf1c6416D8dB846B606A7aB236F28E3d';

// https://apothem.blocksscan.io/address/0x0db96Eb1dc48554bB0f8203A6dE449B2FcCF51a6
export const ACL_ADMIN = '0x0db96Eb1dc48554bB0f8203A6dE449B2FcCF51a6';

// ICollector https://apothem.blocksscan.io/address/0xaaC8D4b35D02176E12b63292957d59a9F321F6AA
export const COLLECTOR = '0xaaC8D4b35D02176E12b63292957d59a9F321F6AA';

// https://apothem.blocksscan.io/address/0xC6e6005F07DdE2c68a1489234293E40D700eFc9d
export const DEFAULT_INCENTIVES_CONTROLLER = '0xC6e6005F07DdE2c68a1489234293E40D700eFc9d';

// https://apothem.blocksscan.io/address/0xCb9ca6A29E8dB2243C04A7001a3a76C5734AE259
export const DEFAULT_FM_TOKEN_IMPL_REV_1 = '0xCb9ca6A29E8dB2243C04A7001a3a76C5734AE259';

// https://apothem.blocksscan.io/address/0x461Cb5247cd8E66F42C684964dE73bA4a954A769
export const DEFAULT_VARIABLE_DEBT_TOKEN_IMPL_REV_1 = '0x461Cb5247cd8E66F42C684964dE73bA4a954A769';

// https://apothem.blocksscan.io/address/0x167419e56731C1A1C437B5aF66B0157Bae379c9e
export const DEFAULT_STABLE_DEBT_TOKEN_IMPL_REV_1 = '0x167419e56731C1A1C437B5aF66B0157Bae379c9e';

// https://apothem.blocksscan.io/address/0xba641CbCdFbA4c8988cC5dEA6201A95BD0E523B7
export const EMISSION_MANAGER = '0xba641CbCdFbA4c8988cC5dEA6201A95BD0E523B7';

// https://apothem.blocksscan.io/address/0x7703C118DacDc3315F098508d5F82c50F00C7c14
export const FAUCET = '0x7703C118DacDc3315F098508d5F82c50F00C7c14';

// https://apothem.blocksscan.io/address/0x045F028Af0992103BaD15febD1FcE1f8C05D46af
export const POOL_ADDRESSES_PROVIDER_REGISTRY = '0x045F028Af0992103BaD15febD1FcE1f8C05D46af';

// https://apothem.blocksscan.io/address/0xa691937627F0aee3839D108b05f08A74668895A2
export const UI_INCENTIVE_DATA_PROVIDER = '0xa691937627F0aee3839D108b05f08A74668895A2';

// https://apothem.blocksscan.io/address/0x6F8bd7DDaf6F7bd5EA9D7fe2Fff876823a7549F0
export const UI_POOL_DATA_PROVIDER = '0x6F8bd7DDaf6F7bd5EA9D7fe2Fff876823a7549F0';

// https://apothem.blocksscan.io/address/0xbfE937302A061C4Fe95E214775B00A087103457C
export const WALLET_BALANCE_PROVIDER = '0xbfE937302A061C4Fe95E214775B00A087103457C';

// https://apothem.blocksscan.io/address/0xf4A0550913e2BB9DF662feE85Ed01D038861352C
export const WETH_GATEWAY = '0xf4A0550913e2BB9DF662feE85Ed01D038861352C';

export const CHAIN_ID = 51;
export const ASSETS = {
  FXD: {
    decimals: 18,
    UNDERLYING: '0xDf29cB40Cb92a1b8E8337F542E3846E185DefF96',
    FM_TOKEN: '0x0546c02e08E6bcBA9f972D85c0531697401F29aA',
    S_TOKEN: '0x23186c482E74Abd83B3E68CEbaa97C5Fc468fff0',
    V_TOKEN: '0x3b21E06E682c9eE038BB51Ce1e884755183C58b8',
    INTEREST_RATE_STRATEGY: '0xb2903c7D88123DBC06aa78E34075a0954D3f9FBD',
    ORACLE: '0x8693538D5DACF77BE1aC523a3a52Dc5E97760a9b',
  },
  WXDC: {
    decimals: 18,
    UNDERLYING: '0xE99500AB4A413164DA49Af83B9824749059b46ce',
    FM_TOKEN: '0xeb4EC81FFe6d5e07FFe6eCB9fCdDf5DBB9c99d62',
    S_TOKEN: '0xF074D345D2dDf1277790286A3A97ea25B56eC771',
    V_TOKEN: '0xd69E08C436F474BC73a5e12bC819672E99e9A11D',
    INTEREST_RATE_STRATEGY: '0xbDbB7395fA28d4057CFA1aDf7003A92b759dd1a7',
    ORACLE: '0xFe26935018eE744b3A70113B8caC615FdD00cfa3',
  },
  CGO: {
    decimals: 18,
    UNDERLYING: '0x97EC6730Fd5F138fCB167cb62A9a4c1A8Be2eD7d',
    FM_TOKEN: '0x7DdFD9e722106fAf3ac422581E5579997C5bA90B',
    S_TOKEN: '0x5BCB69D427829F48557592197587B1f9bb95485c',
    V_TOKEN: '0x89f45d56528DA7638e2baE339F292d7Bc3eAA75e',
    INTEREST_RATE_STRATEGY: '0xbDbB7395fA28d4057CFA1aDf7003A92b759dd1a7',
    ORACLE: '0x70f79a7B428Da6D5ed7364455648764950f6bdFE',
  },
  EURS: {
    decimals: 2,
    UNDERLYING: '0x6440228145324f679Bc1D90dB8252473D9A26650',
    FM_TOKEN: '0x502ebD331756526e6D3128E98236F0Bc44a69eF9',
    S_TOKEN: '0x6696e82B3896E0Be8383d8661681484Bf4f767CA',
    V_TOKEN: '0x61BA1CB3ad1293Bc6651446C34D9029Ad718F881',
    INTEREST_RATE_STRATEGY: '0xa53AeCc186Fd0411e96901923d78C04801b5A858',
    ORACLE: '0x95C954567049D482A2339e07380980FB913DBaFD',
  },
  USDTx: {
    decimals: 6,
    UNDERLYING: '0x9dD4761Bd68169478a06156c0C1416fB9506BE78',
    FM_TOKEN: '0x331EFfe2cc20de72d22dC35600e9f0085b8ff535',
    S_TOKEN: '0x675257d3dA1dc5f5e5d3bE343ca2f6ADDBe8F097',
    V_TOKEN: '0x58522d884678ae80AbB664ee9ffBdB1D6223C076',
    INTEREST_RATE_STRATEGY: '0xa53AeCc186Fd0411e96901923d78C04801b5A858',
    ORACLE: '0x109FF471FA7642A47a890A70f299fe573DB28d0D',
  },
} as const;
export const E_MODES = {
  NONE: 0,
  STABLECOINS: 1,
} as const;
